import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { PurchaseOrderLine } from './PurchaseOrderLine';
import moment from 'moment';
import { TransportBatchStore } from './TransportBatch';

export class ProductionBatch extends Model {
    static backendResourceName = 'production_batch';
    static omitFields = ['inTransport', 'open', 'deliveryDate'];

    @observable id = null;
    @observable quantity = 0;
    @observable readyDate = null;
    @observable productionDate = new moment();
    @observable inTransport = null;
    @observable open = null;
    @observable deliveryDate = null;

    relations() {
        return {
            purchaseOrderLine: PurchaseOrderLine,
            transportBatches: TransportBatchStore,
        };
    }

    casts() {
        return {
            readyDate: Casts.date,
            productionDate: Casts.date,
            deliveryDate: Casts.date,
        };
    }
}

export class ProductionBatchStore extends Store {
    Model = ProductionBatch;
    static backendResourceName = 'production_batch';
}
