import { action, observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { Supplier } from './Supplier';
import { PurchaseOrderLineStore } from './PurchaseOrderLine';
import { Thread, ThreadStore } from 'store/Threads/Thread';
import { ArticleStore } from 'store/Article';

export class PurchaseOrder extends Model {
    static backendResourceName = 'purchase_order';
    static omitFields = [
        'readyStatus',
        'minLoadDate',
        'maxLoadDate',
        'minUnloadDate',
        'maxUnloadDate',
        'latestUnresolvedThreadMessage',
        'numberOfUnresolvedThreads',
    ];

    @observable id = null;
    @observable accepted = false;
    @observable productionDate = null;
    @observable loadDate = null;
    @observable unloadDate = null;

    @observable readyStatus = null;
    @observable minLoadDate = null;
    @observable maxLoadDate = null;
    @observable minUnloadDate = null;
    @observable maxUnloadDate = null;
    @observable latestUnresolvedThreadMessage = null;
    @observable numberOfUnresolvedThreads = null;

    get noteThread() {
        return this.threads.length > 0 ? this.threads.at(0) : new Thread({ purchaseOrder: { id: this.id } }, { relations: ['notes', 'purchaseOrder'] })
    }

    downloadBarcodes(finished=undefined) {
        if (!this.lines){
            return
        }

        const articleCounts = []

        this.lines.forEach(line => {
            let amountString = ''
            if (finished === undefined) {
                amountString = `${line.quantity}x${line.article.id}`
            } else {
                amountString = `${finished ? line.finishedQuantity : line.unfinishedQuantity}x${line.article.id}`
            }
            articleCounts.push(amountString)
        })

        const articleStore = new ArticleStore()
        return articleStore.downloadLabels(articleCounts)
    }


    finishProduction() {
        return this.api.post(`${this.url}finish_production/`).then(action(res => {

            window.viewStore.showSaveNotification()

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }

    relations() {
        return {
            salesOrder: SalesOrder,
            supplier: Supplier,
            lines: PurchaseOrderLineStore,
            threads: ThreadStore
        };
    }

    casts() {
        return {
            productionDate: Casts.date,
            loadDate: Casts.date,
            unloadDate: Casts.date,
            minLoadDate: Casts.date,
            maxLoadDate: Casts.date,
            minUnloadDate: Casts.date,
            maxUnloadDate: Casts.date,
        }
    }
}

export class PurchaseOrderStore extends Store {
    Model = PurchaseOrder;
    static backendResourceName = 'purchase_order';
}
