import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { TransportCompany } from './TransportCompany';
import { Thread } from 'store/Threads/Thread';
import { LoadStore } from './Load';
import { UnloadStore } from './Unload';
import { TransportBatchStore } from './TransportBatch';

export class TransportOrder extends Model {
    static backendResourceName = 'transport_order';
    static omitFields = [ 'latestUnresolvedThreadMessage', 'numberOfUnresolvedThreads', 'startDate', 'endDate','cbm', 'maxCbm', 'partCbm'];
    static STATUSES = ['draft', 'planned', 'confirmed'];

    @observable id = null;
    @observable status = 'draft';

    @observable threads = this.relation(Thread);

    // Annotations
    @observable latestUnresolvedThreadMessage;
    @observable numberOfUnresolvedThreads;
    @observable startDate = null;
    @observable endDate = null;
    @observable cbm = null;
    @observable maxCbm = null;
    @observable partCbm = null;

    relations() {
        return {
            transportCompany: TransportCompany,
            transportBatches: TransportBatchStore,
            loads: LoadStore,
            unloads: UnloadStore,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }
}

export class TransportOrderStore extends Store {
    Model = TransportOrder;
    static backendResourceName = 'transport_order';
}
