import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { ProductionBatch } from './ProductionBatch';
import { Load } from './Load';
import { Unload } from './Unload';
import { TransportOrder } from './TransportOrder';

export class TransportBatch extends Model {
    static backendResourceName = 'transport_batch';
    static omitFields = ['distributed', 'toDistribute', 'loadId', 'unloadId'];

    @observable id = null;
    @observable quantity = 0;
    @observable distributed = null;
    @observable toDistribute = null;
    @observable loadId = null;
    @observable unloadId = null;

    // When moving through the kanbanBoard we need an actionCount for the modal to indicate the amount the modal action is for
    @observable _actionQuantity;


    toBackend(...args) {
        const data = super.toBackend(...args);
        if (this.__changes.includes('loadId')) {
            data.load = this.loadId;
        }
        if (this.__changes.includes('unloadId')) {
            data.unload = this.unloadId;
        }
        return data;
    }

    __parseNewIds(idMaps) {
        if (this.loadId < 0) {
            //eslint-disable-next-line no-unused-vars
            for (const [oldId, newId] of idMaps.load) {
                if (this.loadId === oldId) {
                    this.loadId = newId;
                    break;
                }
            }
        }

        if (this.unloadId < 0) {
            //eslint-disable-next-line no-unused-vars
            for (const [oldId, newId] of idMaps.unload) {
                if (this.unloadId === oldId) {
                    this.unloadId = newId;
                    break;
                }
            }
        }

        return super.__parseNewIds(idMaps);
    }

    relations() {
        return {
            transportOrder: TransportOrder,
            load: Load,
            unload: Unload,
            productionBatch: ProductionBatch,
        };
    }
}

export class TransportBatchStore extends Store {
    Model = TransportBatch;
    static backendResourceName = 'transport_batch';
}
