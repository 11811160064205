import React, { Component } from 'react';
import { Body, ContentContainer } from 're-cy-cle';
import styled from 'styled-components';
import TrippyBackground from 'component/TrippyBackground';
import { Sidebar, SidebarContent } from './Admin/Overview';

const TranslucentSidebar = styled(Sidebar)`
    background-color: rgba(238, 238, 238, 0.85);
    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

const Logo = styled.svg`
    width: 1.25em;
    height: 1.25em;
    position: relative;
    top: 0.25em;
    margin-right: 0.375em;
`;

export const BrandingContainer = styled.a`
    display: block;
    text-align: center;
    margin-top: 25px;
    font-weight: bold;
    font-size: 1.25rem;

    color: rgba(0, 0, 0, 0.3);
    transition: color 600ms ease, filter 600ms ease, transform 600ms ease;
    .yellow {
        transition: color 600ms ease;
    }

    &:hover {
        color: #2d303b;
        filter: drop-shadow(0 0 5px #FFF);
        transform: scale(1.05);
        .yellow {
            color: #ebbb12;
        }
    }
`;

export default class SidebarScreen extends Component {
    Sidebar = TranslucentSidebar;

    renderContent() {
        return null;
    }

    renderBranding() {
        return (
            <BrandingContainer
                href="https://www.codeyellow.nl/"
                target="_blank"
                onClick={(e) => e.stopPropagation()}
            >
                <Logo width="50" height="50" viewBox="0 0 1 1">
                    <path className="yellow" fill="currentColor" d="
                        M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
                        l 0.28125,0.28125
                        l 0.125,-0.125
                        l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
                        l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
                        l 0.15625,0.15625
                        l 0.125,-0.125
                        l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
                    " />
                    <path fill="currentColor" d="
                        M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
                        l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
                        l -0.09375,-0.09375
                        l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
                        l -0.15625,-0.1562
                        l -0.125,0.125
                        l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
                        l -0.15625,0.15625
                    " />
                </Logo>
                {t('brandingMessage')}
            </BrandingContainer>
        );
    }

    render() {
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground>
                        <div style={{ flex: '1' }} />
                        <this.Sidebar>
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {this.renderBranding()}
                        </this.Sidebar>
                    </TrippyBackground>
                </ContentContainer>
            </Body>
        );
    }
}
