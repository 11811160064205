import React from 'react';
import styled from 'styled-components';
import BaseLogin from 'spider/semantic-ui/Login';
import { Sidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview';
import Logo from 'image/logo.svg';

const StyledSidebar = styled(Sidebar)`
    color: #fff !important;
    background-color: ${({ theme }) => theme.primaryColor} !important;

    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;

        > img {
            width: 100%;
            padding: 3rem 0;
        }

        > a {
            color: rgba(255, 255, 255, 0.5);
            &:hover {
                color: #FFF;
            }
        }

        .ui.form .field > label {
            color: #fff !important;
        }

        .ui.form a {
            color: #db4649;
        }
    }
`;

export default class Login extends BaseLogin {
    Sidebar = StyledSidebar;

    renderHeader() {
        return <img src={Logo} alt="Povag" />;
    }
}
