import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction, action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Store } from 'mobx-spine';
import { MyFilterStore } from '../store/MyFilter';
import { Icon, Input, Popup, Button, Dropdown } from 'semantic-ui-react';
import { isSimilar } from '../helpers';
import styled from 'styled-components';
import { camelToSnake } from '../helpers';


const CHAR_CODE_ENTER = 13;

const DEFAULT_BLACKLIST = ['limit', 'currentPage'];


const FilterContainer = styled.div`
    > * {
        margin-left: 0.5rem !important;
    }
`;


const FilterGroup = styled(Button.Group)`
    > :first-child {
        padding-right: 0.25rem !important;
        > .icon {
            margin: 0 0 0 0.25rem !important;
        }
    }
`;


@observer
export default class MyFilters extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        view: PropTypes.string.isRequired,
        whitelist: PropTypes.array,
        blacklist: PropTypes.array,
        defaultParams: PropTypes.object,
        fromUrl: PropTypes.bool,
        activeProps: PropTypes.object,
        inactiveProps: PropTypes.object,
        onFetch: PropTypes.func,
        showCounts: PropTypes.bool,
    };

    static defaultProps = {
        defaultParams: {},
        fromUrl: true,
        activeProps: { primary: true },
        inactiveProps: {},
        showCounts: false,
    };

    constructor(...args) {
        super(...args);
        this.renderFilter = this.renderFilter.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.saveCustomFilter = this.saveCustomFilter.bind(this);
    }

    @observable myFilterStore = new MyFilterStore({
        params: { '.view': this.props.view },
    });
    @observable customName = '';

    isDefault(params) {
        const { defaultParams } = this.props;
        return isSimilar(
            this.filterParams(params),
            this.filterParams(defaultParams),
        );
    }

    componentDidMount() {
        this.storeReaction = reaction(
            () => this.props.store,
            (store) => {
                const { fromUrl, onFetch } = this.props;

                let p = this.myFilterStore.fetch().then(action(() => {
                    for (const filter of this.myFilterStore.models) {
                        filter.params = this.filterParams(filter.params);
                    }
                }));

                // Get current query search parameters
                const currParams = {};
                if (fromUrl) {
                    const { searchParams } = new URL(window.location);
                    for (const key of searchParams.keys()) {
                        currParams[key] = searchParams.get(key);
                    }
                }
                // If none set look for a default filter when filters are fetched
                if (this.isDefault(currParams) || isSimilar(currParams, {})) {
                    p = p.then(() => {
                        for (const filter of this.myFilterStore.models) {
                            if (filter.default) {
                                this.setParams(filter.params, false);
                                break;
                            }
                        }
                    });
                }

                store.wrapPendingRequestCount(p).then(() => {
                    if (onFetch) {
                        onFetch();
                    } else {
                        store.fetch()
                    }
                });
            },
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.storeReaction();
    }

    isAllowed(key) {
        const { whitelist, blacklist } = this.props;

        return !(
            DEFAULT_BLACKLIST.includes(key) ||
            (blacklist && blacklist.includes(key)) ||
            (whitelist && !whitelist.includes(key))
        );
    }

    filterParams(params) {
        const filteredParams = {};
        for (const key of Object.keys(params)) {
            if (this.isAllowed(key)) {
                filteredParams[key] = params[key];
            }
        }

        return filteredParams;
    }

    setParams(params, shouldFetch = true) {
        const { store, onFetch } = this.props;

        const allParams = {...params};
        for (const key of Object.keys(store.params)) {
            if (!this.isAllowed(key)) {
                allParams[key] = store.params[key];
            }
        }

        store.params = allParams;
        if (shouldFetch) {
            if (onFetch) {
                onFetch();
            } else {
                store.fetch();
            }
        }
    }

    @computed get filteredParams() {
        const { store } = this.props;
        return this.filterParams(store.params);
    };

    @computed get activeFilters() {
        return this.myFilterStore.models.filter((f) => (
            isSimilar(f.params, this.filteredParams)
        ));
    }

    renderFilter(filter) {
        const { activeProps, inactiveProps, store, showCounts } = this.props;
        return (
            <FilterGroup
                size="mini"
                {...this.activeFilters.includes(filter) ? activeProps : inactiveProps}
            >
                <Button onClick={() => this.setParams(filter.params)}>
                    {filter.name}
                    {filter.default && <Icon name="check" />}
                    {showCounts && <FilterCounts store={store} params={filter.params} />}
                </Button>
                <Dropdown
                    as={Button}
                    className="icon"
                    floating
                    options={[
                        (
                            filter.default
                            ? { key: 'unsetDefault', icon: 'square outline', text: t('myFilter.action.unsetDefault'), value: 'unsetDefault' }
                            : { key: 'setDefault', icon: 'check square', text: t('myFilter.action.setDefault'), value: 'setDefault' }
                        ),
                        { key: 'setCurrent', icon: 'save', text: t('myFilter.action.setCurrent'), value: 'setCurrent' },
                        { key: 'delete', icon: 'delete', text: t('myFilter.action.delete'), value: 'delete' },
                    ]}
                    trigger={<React.Fragment />}
                    onChange={action((e, { value }) => {
                        switch (value) {
                            case 'unsetDefault':
                                filter.unsetDefault();
                                break;
                            case 'setDefault':
                                Promise.all(
                                    this.myFilterStore
                                    .filter((f) => f.default)
                                    .map((f) => f.unsetDefault())
                                ).then(() => {
                                    filter.setDefault();
                                });
                                break;
                            case 'setCurrent':
                                filter.setParams(this.filteredParams);
                                break;
                            case 'delete':
                                filter.delete().then(() => {
                                    this.myFilterStore.remove(filter)
                                });
                                break;
                            default:
                                // noop
                        }
                    })}
                />
            </FilterGroup>
        );
    }

    handleKeyPress(e) {
        if (e.charCode === CHAR_CODE_ENTER) {
            e.preventDefault();
            this.saveCustomFilter();
        }
    }

    saveCustomFilter() {
        if (this.customName !== '') {
            const { view } = this.props;

            const myFilter = this.myFilterStore.add({
                view,
                name: this.customName,
                params: this.filteredParams,
            });

            this.customName = '';
            myFilter.save().catch(() => {
                this.myFilterStore.remove(myFilter);
            });
        }
    }

    renderCustomFilter() {
        const { activeProps } = this.props;
        return (
            <Button.Group size="mini" {...activeProps}>
                <Button style={{ fontStyle: 'italic' }}>
                    {this.customName === '' ? t('myFilter.custom') : this.customName}
                </Button>
                <Popup
                    on="click" hideOnScroll flowing
                    trigger={<Button icon="add" />}
                    onClose={() => this.customName = ''}
                >
                    <Input
                        ref={(ref) => ref && ref.focus()}
                        size="small"
                        value={this.customName}
                        onChange={(e, { value }) => this.customName = value}
                        onKeyPress={this.handleKeyPress}
                    />
                    <Button
                        primary size="small" icon="save"
                        style={{ marginLeft: '0.5rem' }}
                        onClick={this.saveCustomFilter}
                    />
                </Popup>
            </Button.Group>
        );
    }

    render() {
        const { defaultParams, inactiveProps } = this.props;

        return (
            <FilterContainer>
                {this.myFilterStore.map(this.renderFilter)}
                {!this.isDefault(this.filteredParams) && (
                    <React.Fragment>
                        {this.activeFilters.length === 0 && this.renderCustomFilter()}
                        <Button
                            size="mini" icon="delete"
                            data-test-clear-filters-button
                            onClick={() => this.setParams(defaultParams)}
                            style={{ marginRight: 0 }}
                            {...inactiveProps}
                        />
                    </React.Fragment>
                )}
            </FilterContainer>
        );
    }
}

const Count = styled.span`
    display: inline-block;
    background-color: #db4649;
    color: #fff;

    line-height: 1;
    padding: 0.33333em 0.16667em;
    position: relative;
    margin: -0.5em 0 -0.5em 0.33333em;
    border-radius: 99999px;
    min-width: 1.66667em;
    text-align: center;

    .ui.buttons.primary > .button > & {
        background-color: rgba(255, 255, 255, 0.33333);
    }
`;

@observer
class FilterCounts extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        params: PropTypes.object.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.fetch = this.fetch.bind(this);
    }

    @observable count = 0;

    async fetch() {
        const { store, params } = this.props;
        const data = await store.api.get(`${store.url()}`, {
            ...params,
            with: store.__activeRelations.map(camelToSnake).join(','),
            limit: '0',
        });
        this.count = data.meta.total_records;
    }

    componentDidMount() {
        this.paramsReaction = reaction(
            () => JSON.stringify(this.props.params),
            this.fetch,
            { fireImmediately: true },
        );
        this.storeLoadingReaction = reaction(
            () => this.props.store.isLoading,
            (isLoading) => isLoading && this.fetch(),
        );
    }

    componentWillUnmount() {
        this.paramsReaction();
        this.storeLoadingReaction();
    }

    render() {
        return this.count > 0 && <Count>{this.count}</Count>;
    }
}
