import { observable, computed } from 'mobx';
import { SERVER_DATE_FORMAT } from 'helpers';
import { Model, Store, Casts } from 'store/Base';
import { Article } from './Article';
import { StockMutationStore } from './StockMutation';

export class Stock extends Model {
    static backendResourceName = 'stock';
    static omitFields = ['stock', 'demand', 'hasStock', 'hasDemand'];

    @observable id = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable inProduction = 0;
    @observable toTransport = 0;
    @observable inTransport = 0;
    @observable toDistribute = 0;

    @observable stock = null;
    @observable demand = null;
    @observable hasStock = null;
    @observable hasDemand = null;

    @computed get key() {
        return `${this.article.id},${this.startDate.format(SERVER_DATE_FORMAT)}`;
    }

    relations() {
        return {
            article: Article,
            mutations: StockMutationStore,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }
}

export class StockStore extends Store {
    Model = Stock;
    static backendResourceName = 'stock';
}
