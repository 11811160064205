import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { SalesOrderStore } from 'store/SalesOrder';

export class Customer extends Model {
    static backendResourceName = 'customer';

    @observable id = null;
    @observable name = '';

    @observable emailAddress = '';
    @observable phoneNumber = '';

    @observable street = '';
    @observable number = '';
    @observable numberSuffix = '';
    @observable zipCode = '';
    @observable city = '';
    @observable country = '';

    relations() {
        return {
            salesOrders: SalesOrderStore,
        };
    }
}

export class CustomerStore extends Store {
    Model = Customer;
    static backendResourceName = 'customer';
}
