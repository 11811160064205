import { Casts, Model, Store } from 'store/Base';
import { action, observable } from 'mobx';
import { NoteStore } from './Note';
import { PurchaseOrder } from 'store/PurchaseOrder';
import { User } from '../User';
import { Moment } from 'moment/moment';
import { omit } from 'lodash';
import { camelToSnake } from '../../helpers';
import { SalesOrder } from '../SalesOrder';
import { TransportOrder } from '../TransportOrder';


export class Thread extends Model {
    static backendResourceName = 'thread';

    @observable id = null;
    @observable message = '';
    @observable resolved = false;
    @observable notes = this.relation(NoteStore);

    @observable purchaseOrder = this.relation(PurchaseOrder)
    @observable salesOrder = this.relation(SalesOrder)
    @observable transportOrder = this.relation(TransportOrder)

    @observable createdBy = this.relation(User);
    @observable resolvedBy = this.relation(User);
    @observable createdAt: null | Moment = null;
    @observable resolvedAt: null | Moment = null;

    resolve() {
        return this.api.post(`${this.url}resolve/`).then(action(res => {
            this.saveFromBackend({
                ...res,
                data: omit(res.data, this.fileFields().map(camelToSnake)),
            });

            this.clearUserFieldChanges();
            this.resolved = true
            // @ts-ignore
            window.thread = this
            this.cid = this.cid + 10
            // @ts-ignore
            window.viewStore.showSaveNotification()

        }))
            .catch(action(err => {
                if (err.valErrors) {
                    this.parseValidationErrors(err.valErrors);
                }
                throw err;
            }));
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            resolvedAt: Casts.datetime,
        };
    }
}


export class ThreadStore extends Store {
    Model = Thread;
    static backendResourceName = 'thread';

}
