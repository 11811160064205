import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { SalesOrder } from './SalesOrder';
import { Article } from './Article';

export class SalesOrderLine extends Model {
    static backendResourceName = 'sales_order_line';
    static omitFields = ['distributed', 'toDistribute', 'transported', 'delivered'];

    @observable id = null;
    @observable quantity = 0;
    @observable distributed = null;
    @observable transported = null;
    @observable delivered = null;
    @observable toDistribute = null;

    relations() {
        return {
            salesOrder: SalesOrder,
            article: Article,
        };
    }
}

export class SalesOrderLineStore extends Store {
    Model = SalesOrderLine;
    static backendResourceName = 'sales_order_line';
}
