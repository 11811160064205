import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Customer } from './Customer';
import { PurchaseOrderStore } from './PurchaseOrder';
import { SalesOrderLineStore } from './SalesOrderLine';
import { DistributionOrderStore } from './DistributionOrder';

export class SalesOrder extends Model {
    static backendResourceName = 'sales_order';
    static omitFields = [
        'deliveryIssue',
        'deliveryIssueCount',
        'minDeliveryDate',
        'maxDeliveryDate',
        'latestUnresolvedThreadMessage',
        'numberOfUnresolvedThreads',
    ];

    @observable id = null;
    @observable number = null;
    @observable orderDate = null;
    @observable deliveryDate = null;
    @observable deliveryStatus = 'open';
    @observable invoiceStatus = 'open';
    @observable description = '';
    @observable customerReference = '';
    @observable salesPerson = '';

    @observable deliveryPhoneNumber = '';
    @observable deliveryStreet = '';
    @observable deliveryNumber = '';
    @observable deliveryNumberSuffix = '';
    @observable deliveryZipCode = '';
    @observable deliveryCity = '';
    @observable deliveryCountry = '';

    @observable invoiceName = '';
    @observable invoiceEmail = '';
    @observable invoicePhoneNumber = '';
    @observable invoiceStreet = '';
    @observable invoiceNumber = '';
    @observable invoiceNumberSuffix = '';
    @observable invoiceZipCode = '';
    @observable invoiceCity = '';
    @observable invoiceCountry = '';

    // Annotations
    @observable latestUnresolvedThreadMessage;
    @observable numberOfUnresolvedThreads;
    @observable deliveryIssue = null;
    @observable deliveryIssueCount = null;
    @observable minDeliveryDate = null;
    @observable maxDeliveryDate = null;

    relations() {
        return {
            customer: Customer,
            purchaseOrders: PurchaseOrderStore,
            lines: SalesOrderLineStore,
            distributionOrders: DistributionOrderStore,
        };
    }

    casts() {
        return {
            orderDate: Casts.date,
            deliveryDate: Casts.date,
            minDeliveryDate: Casts.date,
            maxDeliveryDate: Casts.date,
        };
    }
}

export class SalesOrderStore extends Store {
    Model = SalesOrder;
    static backendResourceName = 'sales_order';
}
