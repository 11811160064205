import { action, observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Supplier } from './Supplier';
import { TransportOrder } from './TransportOrder';
import { TransportBatchStore } from './TransportBatch';

export class Load extends Model {
    static backendResourceName = 'load';

    @observable id = null;
    @observable plannedAt = null;
    @observable doneAt = null;

    ship() {
        let data = {};
        console.log('SHIPPING')
        if (this.transportBatches) {
            this.transportBatches.forEach(line => {
                if (line._actionQuantity) {
                    data[line.id] = line._actionQuantity
                }
            })
        }
        return this.api.post(`${this.url}ship/`, data).then(action(res => {

            window.viewStore.showSaveNotification();

        }))
            .catch(action(err => {
                // if (err.valErrors) {
                //     this.parseValidationErrors(err.valErrors);
                // }
                throw err;
            }));
    }


    relations() {
        return {
            supplier: Supplier,
            transportOrder: TransportOrder,
            transportBatches: TransportBatchStore,
        };
    }

    casts() {
        return {
            plannedAt: Casts.datetime,
            doneAt: Casts.datetime,
        }
    }
}

export class LoadStore extends Store {
    Model = Load;
    static backendResourceName = 'load';
}
