import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Article } from './Article';
import { Stock } from './Stock';
import { DistributionOrderLine } from './DistributionOrderLine';
import { PurchaseOrderLine } from './PurchaseOrderLine';
import { ProductionBatch } from './ProductionBatch';
import { TransportBatch } from './TransportBatch';

export class StockMutation extends Model {
    static backendResourceName = 'stock_mutation';

    @observable id = null;
    @observable date = null;
    @observable inProduction = 0;
    @observable toTransport = 0;
    @observable inTransport = 0;
    @observable toDistribute = 0;

    relations() {
        return {
            article: Article,
            stock: Stock,
            distributionOrderLine: DistributionOrderLine,
            purchaseOrderLine: PurchaseOrderLine,
            productionBatch: ProductionBatch,
            transportBatch: TransportBatch,
        };
    }

    casts() {
        return {
            date: Casts.date,
        };
    }
}

export class StockMutationStore extends Store {
    Model = StockMutation;
    static backendResourceName = 'stock_mutation';
}
