import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Customer } from './Customer';
import { TransportOrder } from './TransportOrder';
import { TransportBatchStore } from './TransportBatch';

export class Unload extends Model {
    static backendResourceName = 'unload';

    @observable id = null;
    @observable plannedAt = null;
    @observable doneAt = null;

    relations() {
        return {
            customer: Customer,
            transportOrder: TransportOrder,
            transportBatches: TransportBatchStore,
        };
    }

    casts() {
        return {
            plannedAt: Casts.datetime,
            doneAt: Casts.datetime,
        }
    }
}

export class UnloadStore extends Store {
    Model = Unload;
    static backendResourceName = 'unload';
}
