import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { TransportOrderStore } from './TransportOrder';

export class TransportCompany extends Model {
    static backendResourceName = 'transport_company';

    @observable id = null;
    @observable name = '';
    @observable email = '';

    relations() {
        return {
            transportOrders: TransportOrderStore,
        };
    }
}

export class TransportCompanyStore extends Store {
    Model = TransportCompany;
    static backendResourceName = 'transport_company';
}
